import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <p>{`Use a radio button in a `}<a parentName="p" {...{
        "href": "/components/radio-group"
      }}>{`radio group`}</a>{` to allow users to select one option from a short list of options.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/components/radio-group"
      }}>{`radio group guidelines`}</a>{` for more information about usage.`}</p>
    <h3>{`States`}</h3>
    <p>{`Radio buttons have static dimensions and three different states when checked or unchecked: rest (default), focus, and disabled.`}</p>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/224133514-c583baf4-6dfc-444d-8714-dfc88cc00ff9.png" />
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`A radio button may not be used on its own: it `}<em parentName="li">{`must`}</em>{` be rendered in a group of related radio buttons using a `}<a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`radio group`}</a>{`.`}</li>
      <li parentName="ul">{`Radio buttons cannot be unchecked, so only use radio buttons when a selection is required`}</li>
      <li parentName="ul">{`An individual radio should not have its own validation message or style. Instead, show a validation message on the `}<a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`radio group`}</a>{`. For more information, see the `}<a parentName="li" {...{
          "href": "../ui-patterns/forms/overview#validation"
        }}>{`Validation Message`}</a>{` section in the Forms documentation.`}</li>
      <li parentName="ul">{`An individual radio button cannot be marked as required. Instead, make a selection required using the `}<a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`radio group`}</a>{`. For more information, see the `}<a parentName="li" {...{
          "href": "../ui-patterns/forms/overview#required-field-indicator"
        }}>{`Required field indicator`}</a>{` in the Forms documentation.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Radio" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../ui-patterns/forms/overview"
        }}>{`Forms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/form-control"
        }}>{`Form control`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/checkbox"
        }}>{`Checkbox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`Radio group`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      